
// const observer = new IntersectionObserver((entries) => {
//     entries.forEach(e => {
//         if (e.isIntersecting) {
//             if (!e.target.classList.contains('animar')) {
//                 console.log("NA INCLUDES", e.target.classList)
//                 e.target.classList.add('animar');

//             } else {
//                 console.log("INCLUDES", e.target.classList)
//             }
//         } else {
//             e.target.classList.remove('animar');
//         }
//     });
// });

// const elementosAnimados = document.querySelectorAll('.animado');

// elementosAnimados.forEach(el => observer.observe(el));
// var sal = require('sal.js');
import sal from "sal.js"


sal({
    threshold: 0.5,
    once: false,
});




if (document.readyState !== 'loading') {
    console.log('document is already ready, just execute code here');
    setTimeout(iniciar, 2500);
} else document.addEventListener('DOMContentLoaded', (event) => {
    console.log('loaded')
    setTimeout(iniciar, 2500);
});

document.addEventListener('scroll', initOnEvent);
document.addEventListener('mousemove', initOnEvent);
document.addEventListener('touchstart', initOnEvent);

function initOnEvent(event) {
    iniciar();
    event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
}

async function initPixel(f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);


    fbq('init', '7564065500290435');
    fbq('track', 'PageView');
}

async function iniciar() {
    if (window.DidInit) {
        return false;
    }
    console.log('Iniciar libs')
    window.DidInit = true;

    try {
        initPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    } catch {
        console.log('Erro ao iniciar o pixel')
    }

    // initGtm(window, document, 'script', 'dataLayer', 'GTM-MPN6L4P3');

}
